export const routesNames = {
  '/kds': 'KDS - Gerenciador de pedidos',
  '/campaigns': 'Notificação PUSH',
  '/app/banners': 'Banners Aplicativo',
  '/app/banners/new': 'Novo Banner',
  '/subscription': 'Clube de Assinatura',
  '/deliveryarea/comission': 'Acerto de Motoboy',
  '/loyalty': 'Fidelidade',
  '/loyalty/programs/cashback': 'Fidelidade - Programa de cashback',
  '/loyalty/programs/points': 'Fidelidade - Programa de pontos',
  '/loyalty/promocode': 'Promocodes',
  '/loyalty/reports': 'Relatórios',
  '/loyalty/cards': 'Cartão',
  '/finance/voucher': 'Voucher',
  '/loyalty/cards/new': 'Novo',
  '/stores/groups/new': 'Novo Grupo',
  '/stores/groups': 'Grupos de Lojas',
  '/store/configs': 'Configurações Master',
  '/store/devices': 'Lista de Lojas',
  '/register/employees': 'Funcionários',
  '/register/users': 'Usuários do sistema',
  '/register/providers': 'Fornecedores',
  '/tickets': 'Suporte',
  '/tickets/new': 'Novo Ticket de Suporte',
  '/stock': 'Estoque',
  '/nfe/import/nfe': 'Importar Nf-e',
  '/nfe/manifest': 'Importar Manifesto',
  '/nfe/nfekey': 'Importar NFE por chave de nota',
  '/nfe/manual/nfe': 'Lançamento Manual',
  '/register/access': 'REMOVER',
  '/': 'Home',
  '/scheduled': 'Pedidos agendados',
  '/store/withdraws': 'Motivos de Sangria',
  '/store/tables': 'Mesas',
  '/nfce/list': 'Exportar NFC-e',

  '/fiscal/export': 'Exportar NFC-E',
  '/fiscal/create': 'Emitir NFC-E',
  '/fiscal/serie': 'Configurar Série NFC-E',
  '/store/tables/new': 'Nova Mesa',
  '/store/accounts': 'Comandas',
  '/store/accounts/new': 'Nova Comandas',
  '/store/nfce': 'Configurações de NFCe',
  '/store/nfce/:id': 'Editar Configurações de NFCe',
  '/store/integrations': 'Integrações',
  '/store/integrations/:id': 'Configurar Integrações',
  '/store/paymentintegration': 'Integrações de Pagamentos Digitais',
  '/store/paymentintegration/:id':
    'Configurar Integrações de Pagamentos Digitais',
  '/store/royalties/marketing': 'Configuração Royalties/Fundo de Propaganda',
  '/store/pdv': 'Configurar PDV',
  '/dashboard': 'Dashboard',
  '/finance/withdraw': 'Relatório de Sangrias',
  '/opreports/sales': 'Relatório de Vendas',
  '/opreports/store': 'Relatório de Vendas por Loja',
  '/opreports/monthsale': 'Relatório de Resultados',
  '/whats': 'Bot do whatsapp',
  '/campaigns/whats/create': 'Criar novo template de campanha',
  '/opreports/salesuser': 'Relatório de Vendas X Operador',
  '/opreports/sources': 'Relatório de Origens',
  '/finance/cashconference': 'Relatório de Conferência de caixa',
  '/reports/receiptexpenses': 'Relatório de Receitas e Despesas',
  '/reports/royalties': 'Relatório de Royalties',
  '/opreports/orders/daily': 'Relatório de Vendas por dia',
  '/opreports/items': 'Relatório de Produtos',
  '/opreports/deliveryman': 'Relatório de Motoboys',
  '/opreports/kds': 'Relatório de KDS',
  '/opreports/salestime': 'Relatório de Vendas X Horário',
  '/opreports/ordes/payments': 'Relatório de Pedidos X Pagamentos',
  '/opreports/optinals': 'Relátorio de Produtos X Opcionais',
  '/opreports/source': 'Relatório de Pedidos X Canal',
  '/opreports/categories': 'Relatório de Categorias',
  '/opreports/items/promocode': 'Relatório de Produtos X Promocodes',
  '/reports/customers': 'Clientes',
  '/reports/finance': 'Faturamento Consolidado X Loja',
  '/reports/customers/detail/': 'Detalhes',
  '/finance/portalnfe': 'Portal de Notas',
  '/finance/cashflow': 'Contas a Pagar e Receber',
  'finance/voucher': 'Vales',
  '/finance/cashflow/ofx': 'OFX',
  '/finance/voucher/new': 'Cadastro de Vale',
  '/finance/payments': 'Formas de Pagamento',
  '/finance/workshift': 'Caixa',
  '/finance/expenses': 'Despesas',
  '/finance/discount': 'Descontos',
  '/finance/ofx': 'Impotar OFX',
  '/finance/tributes': 'Tributos',
  '/finance/categories': 'Categorias Financeiras',
  '/finance/discount/new': 'Gerenciar descontos',
  '/finance/banks': 'Contas Bancárias',
  '/finance/receipt': 'Contas a Receber',
  '/finance/pay': 'Contas a Pagar',
  '/finance/report': 'Relatórios Financeiros',
  '/finance/dre': 'Modelos de DRE',
  '/finance/dre/new': 'Criar Modelo de DRE',
  '/finance/dre/:id': 'Editar Modelo de DRE',
  '/providers': 'Fornecedores',
  '/providers/:id': 'Editar fornecedor',
  '/products/catalog': 'Cardápio PDV2.0', // temporário
  '/products/pdv': 'Cardápio PDV',
  '/product/app': 'Cardápio do Aplicativo',
  '/products/ifoodcatalog': 'Cardápio do Ifood',
  '/products/categories': 'Categorias de Produto',
  '/products/availability': 'Disponibilidade de Produtos X Menu',
  '/products/variations': 'Variações de produto',
  '/products/variations/new': 'Nova variação',
  '/products/new': 'Novo Produto',
  '/products/copy': 'Duplicar Produto',
  '/products': 'Produtos',
  '/products/type/0': 'Produtos de Venda',
  '/products/type/2': 'Produtos de Consumo Interno',
  '/deliveryarea/neighborhoods': 'Entrega por Bairro',
  '/deliveryarea/map': 'Entrega por Raio',
  '/deliveryarea/neighborhoods/:id': 'Configuração',
  '/deliveryarea/zipcode': 'Entrega por CEP',
  '/deliveryarea/deliverers': 'Entregadores',
  '/deliveryarea/deliverers/new': 'Novo Entregador',

  '/digital/ui': 'Configuração da identidade visual',
  '/digital/hours': 'Configuração da horários por loja',
  '/digital/hours/:id': 'Editar configuração',
  '/digital/minvalue': 'Configuração de valor mínimo do pedido',
  '/digital/minvalue/:id': 'Editar configuração',
  '/digital/banners': 'Configuração dos Banners de Site/App',

  '/salespoint': 'Pontos de Venda',
  '/salespoint/ui': 'Configuração da identidade visual',

  '/stock/techinal': 'Ficha Técnica',
  '/stock/send': 'NF-E',
  '/stock/inventory': 'Ajustar Estoque',
  '/stock/report': 'Relatórios de Estoque',
  '/stock/send/new': 'Gerar NF-E',
  '/stock/techinal/new': 'Nova ficha Técnica',
  '/components': 'Components',
  '/charts': 'Charts',
  '/components/buttons': 'Buttons',
  '/components/social-buttons': 'Social Buttons',
  '/components/cards': 'Cards',
  '/components/forms': 'Forms',
  '/components/modals': 'Modals',
  '/components/switches': 'Switches',
  '/components/tables': 'Tables',
  '/components/tabs': 'Tabs',
  '/forms': 'Forms',
  '/forms/basic-forms': 'Basic Forms',
  '/forms/advanced-forms': 'Advanced Forms',
  '/editors': 'Editors',
  '/editors/text-editors': 'Text Editors',
  '/editors/code-editors': 'Code Editors',
  '/icons': 'Icons',
  '/icons/font-awesome': 'Font Awesome',
  '/icons/simple-line-icons': 'Simple Line Icons',
  '/plugins': 'Plugins',
  '/plugins/calendar': 'Calendar',
  '/plugins/datatable': 'Data Table',
  '/plugins/loading-buttons': 'Loading Buttons',
  '/plugins/notifications': 'Notifications',
  '/plugins/spinners': 'Spinners',
  '/widgets': 'Widgets',
  '/google-maps': 'Google Maps',
  '/ui-kits': 'UI Kits',
  '/ui-kits/invoicing': 'Invoicing',
  '/ui-kits/invoicing/invoice': 'Invoice',
  '/ui-kits/email/compose': 'Compose',
  '/ui-kits/email/inbox': 'Inbox',
  '/ui-kits/email/message': 'Message',
  '/callcenter': 'Callcenter',
  '/callcenter/new': 'Novo pedido',
  '/notify-pdv-messages': 'Mensagens PDV',
};
